export const CALENDAR_SEARCH = "CALENDAR_SEARCH";

export const calendar_search = data => ({
  type: CALENDAR_SEARCH,
  payload: {
    request: {
      method: "post",
      url: "calendar_search_v3",
      data: data
    }
  }
});
