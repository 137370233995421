const forms = {
  input: {
    color: "primary",
    px: "16px",
    py: "14px",
    borderColor: "gray50",
    "::placeholder": {
      color: "gray"
    },
    backgroundColor: "white"
  },
  select: {
    borderRadius: 9999
  },
  textarea: {},
  text: { color: "red" },
  label: {},
  radio: {},
  checkbox: {}
};

export default forms;
