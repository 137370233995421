import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
const ReduxWrapper = ({ children }) => {
  if (typeof window === "undefined") {
    return <Provider store={store}>{children}</Provider>;
  }
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

ReduxWrapper.propTypes = {
  children: PropTypes.any
};

export default ReduxWrapper;
