import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import axios from "axios";
import { multiClientMiddleware } from "redux-axios-middleware";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const clients = {
  default: {
    client: axios.create({
      baseURL: "https://www.rdv.nc/.netlify/functions/",
      responseType: "json",
      withCredentials: true,
      crossdomain: true,
      headers: { "Content-Type": "application/json" }
    })
  }
};
const persistConfig = {
  key: "root",
  // whitelist: ["persist", "currentUser"],
  blacklist: ["rdv"],
  storage
};
const composeEnhancers =
  process.env.NODE_ENV === "development" && typeof window !== "undefined"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          trace: true,
          traceLimit: 25
        })
      : compose
    : compose;

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, multiClientMiddleware(clients)))
);

const persistor = persistStore(store);

export { store, persistor };
