import menusMd from "./menus.md";
import siteinfosMd from "./siteinfos.md";
import themeMd from "./theme.md";
import legalsMd from "./legals.md";

export default {
  menus: menusMd.attributes,
  siteinfos: siteinfosMd.attributes,
  theme: themeMd.attributes,
  legals: legalsMd.attributes
};

export const menus = menusMd.attributes;
export const siteinfos = siteinfosMd.attributes;
export const theme = themeMd.attributes;
export const legals = legalsMd.attributes;
