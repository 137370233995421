import styled, { createGlobalStyle, css } from "styled-components";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { normalize } from "polished";

const icofontCss = css`
  ${import("../components/UI/Icofont/icofont/icofont.min.css")}
`;

const GlobalStyle = createGlobalStyle`

${icofontCss}
${normalize()}
.drawer-content{
  background-color:${themeGet("colors.background")};
  color: ${themeGet("colors.text")};
}
  body {
    background-color:${themeGet("colors.background")};
    font-family: ${themeGet("fonts.body")};
    color: ${themeGet("colors.text")};
    font-size:${themeGet("fontSizes.2")}px;
    margin: 0;
  }
  *{
    font-family: ${themeGet("fonts.body")};
    &:focus {
    outline: none;
    outline-color: transparent;
    outline-style: auto;
    outline-width: 0px;
}
a{
  color:inherit;
  text-decoration:inherit;
  font-size:inherit;
  font-weight:inherit;
}
  }
  img{
    max-width:100%;
  }
  .react-parallax-bgimage{
    max-width:unset;
  }
  .mapboxgl-ctrl-attrib{
    display:none !important;
  }
`;

/* Wiltech wrapper style */
const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
