import { CALENDAR_SEARCH } from "../actions/rdv";

const initialState = {
  dispos: process.env.NODE_ENV !== 'development' ? []:[
    "1626316200",
    "1626319800",
    "1626323400",
    "1626327000",
    "1626382800",
    "1626386400",
    "1626390000",
    "1626393600",
    "1626402600",
    "1626406200",
    "1626409800",
    "1626642000",
    "1626645600",
    "1626649200",
    "1626652800",
    "1626661800",
    "1626665400",
    "1626669000",
    "1626672600",
    "1626728400",
    "1626732000",
    "1626735600",
    "1626739200",
    "1626748200",
    "1626751800",
    "1626755400",
    "1626759000",
    "1626814800",
    "1626818400",
    "1626822000",
    "1626825600",
    "1626834600",
    "1626838200",
    "1626841800",
    "1626845400",
    "1626901200",
    "1626904800",
    "1626908400",
    "1626912000",
    "1626921000",
    "1626924600",
    "1626928200",
    "1626931800",
    "1626987600",
    "1626991200",
    "1626994800",
    "1626998400",
    "1627007400",
    "1627011000",
    "1627014600",
    "1627246800",
    "1627250400",
    "1627254000",
    "1627257600",
    "1627266600",
    "1627270200",
    "1627273800",
    "1627277400",
    "1627333200",
    "1627336800",
    "1627340400",
    "1627344000",
    "1627353000",
    "1627356600",
    "1627360200",
    "1627363800",
    "1627419600",
    "1627423200",
    "1627426800",
    "1627430400",
    "1627439400",
    "1627443000",
    "1627446600",
    "1627450200",
    "1627506000",
    "1627509600",
    "1627513200",
    "1627516800",
    "1627525800",
    "1627529400",
    "1627533000",
    "1627536600",
    "1627592400",
    "1627596000",
    "1627599600",
    "1627603200",
    "1627612200",
    "1627615800",
    "1627619400"
  ]
};

export { initialState };
export default function rdv(state = initialState, action) {
  if (process.env.NODE_ENV === "development") {
    console.log(action);
  }
  switch (action.type) {
    case CALENDAR_SEARCH:
      return {
        ...state,
        loading: true
      };
    case CALENDAR_SEARCH + "_FAIL":
      return {
        ...state,
        loading: false
      };
    case CALENDAR_SEARCH + "_SUCCESS":
      return {
        ...state,
        loading: false,
        dispos: action.payload.data
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
